import {
    Button,
    DEFAULT_BREAKPOINTS,
    GlobalHeaderCtaButtons,
    GlobalHeaderLite,
    GlobalHeaderMainBar,
    GlobalHeaderMobileMenu,
    IconPhone,
    MainNavMenu,
    MainNavMenuItem,
    useMatchMediaQueryPreset,
    IconArrowRightCircled,
    Text,
    ProgressIndicatorOverlayStateSetterContext,
} from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { BASE_URL_PREFIX } from '../../shared/constants';
import {
    getBookingLink,
    getHyphenatedText,
    loadInvoca,
    getUrlQueryString,
    getWorkingHoursHelper,
} from '../../shared/helpers';
import { PagesContext } from '../../state/pages.context';
import Link from 'next/link';
import styles from './index.module.css';
import getConfig from 'next/config';
import { NextHead } from '../head';
import { IHead } from '../../interfaces/shared-props';
import { AhsLogoDesk } from './ahsLogoDesk';
import { AhsLogoNotDesk } from './ahsLogoNotDesk';
import { getMarketsServices } from '../../services/zesty';
import { HeaderBubble } from '../header-bubble';
import { isWithinWorkingHours } from '../header/helpers';
import { utcToZonedTime } from 'date-fns-tz';
import { IWorkingHours } from '../../interfaces/zesty';

interface INavItem {
    id: string;
    name: string;
    icon?: string;
}

type ParentNavItemId = 'market' | 'serviceType';

interface IMobileLink {
    name: string;
    href?: string;
    subNavItems: {
        name: string;
        href: string;
        isActive?: boolean;
    }[];
}

export function DefaultHeader({
    pageTitle = 'Home',
    pageDescription,
    titleLeft,
    shouldIndexPage,
}: IHead) {
    const [
        /**
         * This hack is used to immediately hide the menu dropdown when one of the navMenu dropdown Links have been clicked
         */
        showEmptyDropdownHack,
        setShowEmptyDropdownHack,
    ] = useState(false);
    const [shouldMobileMarketsCollapse, setShouldMobileMarketsCollapse] =
        useState(false);
    const router = useRouter();
    const { sid } = router.query;
    const prevMarketId = useRef<string | undefined>();

    const {
        publicRuntimeConfig: { FORMATTED_PHONE = '' },
    } = getConfig();

    const {
        marketsServices,
        userMarket,
        availableServices,
        setMarketsServices,
        setUserMarket,
    } = useContext(PagesContext);
    const { startLoading, endLoading } = useContext(
        ProgressIndicatorOverlayStateSetterContext,
    );

    const URLParams: Record<string, string | number> = {};

    const queryString = router.asPath.split('?')[1]
        ? router.asPath.split('?')[1]
        : '';

    Object.entries(router.query).forEach(([key, value]) => {
        if (queryString.includes(key) && key != 'superm')
            URLParams[key] = `${value}`;
    });

    const URLRecords = getUrlQueryString(URLParams);

    const [workingHours, setWorkingHours] = useState<IWorkingHours | null>(
        null,
    );

    const [showCallNumber, setShowCallNumber] = useState(false);

    useEffect(() => {
        (async () => {
            const workingHours = await getWorkingHoursHelper();
            setWorkingHours(workingHours);
        })();
    }, []);

    useEffect(() => {
        setShowCallNumber(
            (workingHours &&
                isWithinWorkingHours({
                    date: utcToZonedTime(new Date(), 'America/Los_Angeles'),
                    workingHours,
                })) ||
                false,
        );
    }, [workingHours]);

    useEffect(() => {
        (async () => {
            const marketsServicesData = await getMarketsServices();
            marketsServicesData && setMarketsServices(marketsServicesData);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (marketsServices) {
                const selectedMarket = marketsServices?.find(
                    (x) => x.marketId === router.query.superm,
                );
                if (selectedMarket) {
                    setUserMarket({
                        id: selectedMarket.marketId,
                        name: selectedMarket.marketName,
                    });
                }
            }
        })();
    }, [router.query.superm, marketsServices, setUserMarket]);

    useEffect(() => {
        loadInvoca({
            marketId: userMarket.id,
            sid: sid as string,
        });
    }, [userMarket.id, sid]);

    useEffect(() => {
        endLoading();

        if (isDesktop) {
            setShowEmptyDropdownHack(false);
        } else {
            if (prevMarketId.current) {
                setShouldMobileMarketsCollapse(true);
            }
            prevMarketId.current = userMarket.id;
        }
    }, [userMarket.id]);

    const isDesktop = useMatchMediaQueryPreset(
        'lg-and-above',
        DEFAULT_BREAKPOINTS,
        'lg',
    );

    const handleNavItemClick = (navItemName: string, showLoader: boolean) => {
        if (showLoader && navItemName !== 'Appliance Repair') {
            startLoading();
        }
        setShowEmptyDropdownHack(true);
    };

    const handleMobileNavItemClick = ({
        navItemName,
        url,
    }: {
        navItemName: string;
        url: string;
    }) => {
        if (
            navItemName !== 'Appliance Repair' &&
            navItemName === marketsLinkName
        ) {
            startLoading();
        }
        router.push(url);
    };

    const getSubNavRenderCondition = (
        totalColumns: number,
        column: number,
        subNavItemNumber: number,
        navItemsLength: number,
    ) => {
        const avgColumnRows = Math.floor(navItemsLength / totalColumns);
        let column1Rows = avgColumnRows,
            column2Rows = avgColumnRows;

        if (navItemsLength % totalColumns === 1) {
            column1Rows = avgColumnRows + 1;
        } else if (navItemsLength % totalColumns === 2) {
            column1Rows = column2Rows = avgColumnRows + 1;
        }

        switch (column) {
            case 1:
                return subNavItemNumber <= column1Rows;
            case 2:
                return (
                    subNavItemNumber > column1Rows &&
                    subNavItemNumber <= column1Rows + column2Rows
                );
            case 3:
                return subNavItemNumber > column1Rows + column2Rows;
            default:
                return false;
        }
    };

    const checkMarketURL = () => {
        const hasMarket = Object.keys(router.query).find(
            (key) => key === 'superm',
        );
        return hasMarket ? '' : '-r';
    };

    const markets = marketsServices.map((x) => ({
        id: x.marketId,
        name: x.marketName,
        services: x.services,
    }));
    const serviceTypes =
        marketsServices.find((x) => x.marketId === userMarket.id)?.services ||
        [];

    const marketsTotalColumns =
        markets?.length <= 6 ? 1 : markets?.length <= 12 ? 2 : 3;

    const servicesTotalColumns =
        serviceTypes && serviceTypes.length > 9 ? 2 : 1;

    const logoUrl =
        userMarket.id || router.query?.superm
            ? `/${URLRecords != '' ? URLRecords + '&' : '?'}superm=${
                  userMarket.id || router.query?.superm
              }`
            : '/' + URLRecords;

    const getMarketLink = (navItem: INavItem, addBasePrefix?: boolean) => {
        const isPricePage = window.location.pathname.includes('/pricing');

        return isPricePage
            ? `/pricing-${getHyphenatedText(navItem.name).replaceAll(
                  '-/',
                  '',
              )}/pr/${navItem.id}`
            : `${window.location.pathname}${
                  addBasePrefix ? BASE_URL_PREFIX : ''
              }${URLRecords != '' ? URLRecords + '&' : '?'}superm=${
                  navItem.id
              }`;
    };

    const pricesUrl = `${BASE_URL_PREFIX}/pricing-${getHyphenatedText(
        userMarket.name ? userMarket.name.replaceAll(' / ', '-') : '',
    )}${checkMarketURL()}/pr/${userMarket.id}${URLRecords}`;
    const howItWorksUrl = `${BASE_URL_PREFIX}/how-it-works-${getHyphenatedText(
        userMarket.name ? userMarket.name.replaceAll(' / ', '-') : '',
    )}/hw/${userMarket.id}${URLRecords}`;

    const marketsLinkName = 'Markets';
    const servicesLinkName = 'Services';
    const mobileLinks: Array<IMobileLink> = [
        {
            name: marketsLinkName,
            subNavItems: markets.map((x) => ({
                name: x.name,
                href: getMarketLink(x, true),
                isActive: x.id === userMarket.id,
            })),
        },
        {
            name: servicesLinkName,
            subNavItems: serviceTypes.map((x) => ({
                name: x.name,
                href: getBookingLink({
                    market: userMarket,
                    serviceType: x,
                }),
            })),
        },
        {
            name: 'Prices',
            subNavItems: [],
            href: pricesUrl,
        },
        {
            name: 'How It Works',
            subNavItems: [],
            href: howItWorksUrl,
        },
        {
            name: 'FAQ',
            subNavItems: [],
            href: `${BASE_URL_PREFIX}/faq`,
        },
    ];

    const noServicesMessage =
        'To see available services, please select a city from the market drop-down.';

    const handleResetIsMobileMarketsCollapsed = (menuItemName: string) => {
        if (menuItemName === marketsLinkName && shouldMobileMarketsCollapse) {
            setShouldMobileMarketsCollapse(false);
        }
    };

    const renderDesktopDropdown = ({
        parentNavItemId,
        totalDropdownColumns,
        navItems,
        linkFn,
        reloadDocOnLinkClick,
        reduceDesktopRowsHeight,
    }: {
        parentNavItemId: ParentNavItemId;
        totalDropdownColumns: number;
        navItems: Array<INavItem>;
        linkFn: (navItem: INavItem) => string;
        reloadDocOnLinkClick?: boolean;
        reduceDesktopRowsHeight?: boolean;
    }) => {
        const subNavLink = (navItem: INavItem) => (
            <a
                href={linkFn(navItem)}
                onClick={() =>
                    handleNavItemClick(navItem.name, !reloadDocOnLinkClick)
                }
                className={clsx(
                    'font-bold hover:underline',
                    navItem.icon && 'bg-no-repeat bg-contain bg-left pl-7',
                    styles['sub-nav-link'],
                    navItem.id === userMarket.id &&
                        styles['sub-nav-link-disabled'],
                )}
                style={{
                    backgroundImage: navItem.icon
                        ? `url(${navItem.icon})`
                        : 'unset',
                }}
            >
                {navItem.name}
            </a>
        );

        if (showEmptyDropdownHack) {
            return (
                <div id={`${parentNavItemId}-empty`}>
                    <div id={`${parentNavItemId}-empty-column`}></div>
                </div>
            );
        }

        return (
            <div
                id={`${parentNavItemId}-dropdown`}
                className={clsx(
                    `grid grid-cols-${totalDropdownColumns} gap-4 py-4 px-3`,
                    totalDropdownColumns === 3 &&
                        styles['subnav-dropdown-3-columns'],
                )}
            >
                {Array.from(Array(totalDropdownColumns), (_, i) => i + 1).map(
                    (column) => (
                        <div
                            id={`${parentNavItemId}-column-${column}`}
                            key={column}
                        >
                            {navItems && navItems.length >= 1
                                ? navItems?.map(
                                      (navItem, i) =>
                                          getSubNavRenderCondition(
                                              totalDropdownColumns,
                                              column,
                                              i + 1,
                                              navItems.length,
                                          ) && (
                                              <div
                                                  key={i}
                                                  id={`${parentNavItemId}-${column}-${i}`}
                                                  className={clsx(
                                                      styles['sub-nav-item'],
                                                      reduceDesktopRowsHeight &&
                                                          styles[
                                                              'sub-nav-item-shorter'
                                                          ],
                                                  )}
                                              >
                                                  {reloadDocOnLinkClick ? (
                                                      subNavLink(navItem)
                                                  ) : (
                                                      <Link
                                                          href={linkFn(navItem)}
                                                      >
                                                          {subNavLink(navItem)}
                                                      </Link>
                                                  )}
                                              </div>
                                          ),
                                  )
                                : null}
                        </div>
                    ),
                )}
            </div>
        );
    };

    const renderMobileDropdown = () => (
        <GlobalHeaderMobileMenu closeOnClick={false}>
            <MainNavMenu
                label="Main Navigation Menu"
                preserveDomElement
                lazyInitialCollapse
            >
                {mobileLinks.map((x) => {
                    const id = getHyphenatedText(x.name);
                    return (
                        <MainNavMenuItem
                            key={id}
                            id={id}
                            collapsable={!x.href}
                            href={x.href}
                            onClick={() =>
                                handleResetIsMobileMarketsCollapsed(x.name)
                            }
                        >
                            {x.name}
                            {x.subNavItems && x.subNavItems.length >= 1
                                ? x.subNavItems.map((subNavItem, i) => (
                                      <MainNavMenuItem
                                          id={`${id}-${i}`}
                                          key={i}
                                          onClick={() =>
                                              handleMobileNavItemClick({
                                                  navItemName: x.name,
                                                  url: subNavItem.href,
                                              })
                                          }
                                          defaultActive={
                                              shouldMobileMarketsCollapse
                                                  ? false
                                                  : !!subNavItem.isActive
                                          }
                                      >
                                          {subNavItem.name}
                                      </MainNavMenuItem>
                                  ))
                                : x.name === servicesLinkName && (
                                      <Text
                                          id="no-services-msg"
                                          className="px-5 py-3"
                                      >
                                          {noServicesMessage}
                                      </Text>
                                  )}
                        </MainNavMenuItem>
                    );
                })}
            </MainNavMenu>
        </GlobalHeaderMobileMenu>
    );

    return (
        <>
            <NextHead
                titleLeft={titleLeft}
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                shouldIndexPage={shouldIndexPage}
            />
            <div
                className={clsx({
                    'default-header flex flex-col': true,
                    'two-markets-columns': marketsTotalColumns === 2,
                    'two-services-columns': servicesTotalColumns === 2,
                })}
            >
                <GlobalHeaderLite desktop={isDesktop}>
                    <GlobalHeaderMainBar>
                        <div className="flex-1 min-w-0 flex h-full items-center lg:justify-between lg:mr-8">
                            {!isDesktop && renderMobileDropdown()}
                            <Link href={logoUrl}>
                                <a>
                                    {isDesktop ? (
                                        <AhsLogoDesk />
                                    ) : (
                                        <AhsLogoNotDesk />
                                    )}
                                </a>
                            </Link>
                            {isDesktop ? (
                                <MainNavMenu
                                    variant="horizontal"
                                    color="primary"
                                    label="Main Navigation Menu"
                                    className="ml-4"
                                >
                                    <MainNavMenuItem id="markets">
                                        {availableServices.length > 0 &&
                                            userMarket.name}
                                        {!availableServices.length &&
                                            'Pick a Market'}
                                        {markets.length >= 1
                                            ? renderDesktopDropdown({
                                                  parentNavItemId: 'market',
                                                  totalDropdownColumns:
                                                      marketsTotalColumns,
                                                  navItems: markets,
                                                  linkFn: (navItem) =>
                                                      getMarketLink(navItem),
                                                  reduceDesktopRowsHeight: true,
                                              })
                                            : null}
                                    </MainNavMenuItem>
                                    <MainNavMenuItem id="services">
                                        Services
                                        {serviceTypes &&
                                        serviceTypes.length >= 1 ? (
                                            renderDesktopDropdown({
                                                parentNavItemId: 'serviceType',
                                                totalDropdownColumns:
                                                    servicesTotalColumns,
                                                navItems: serviceTypes,
                                                linkFn: (navItem) =>
                                                    getBookingLink({
                                                        market: userMarket,
                                                        serviceType: navItem,
                                                        params: URLParams,
                                                    }),
                                                reloadDocOnLinkClick: true,
                                            })
                                        ) : (
                                            <Text
                                                id="no-services-msg"
                                                className="px-4 py-3"
                                            >
                                                {noServicesMessage}
                                            </Text>
                                        )}
                                    </MainNavMenuItem>
                                    <MainNavMenuItem
                                        id="prices"
                                        href={pricesUrl}
                                    >
                                        Prices
                                    </MainNavMenuItem>
                                    <MainNavMenuItem
                                        id="how-it-works"
                                        href={howItWorksUrl}
                                    >
                                        How It Works
                                    </MainNavMenuItem>
                                </MainNavMenu>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="flex h-full items-center">
                            <GlobalHeaderCtaButtons>
                                <div
                                    className={clsx(
                                        'text-center',
                                        styles['header-ctas'],
                                    )}
                                >
                                    {isDesktop && (
                                        <Button
                                            href={getBookingLink({
                                                market: userMarket,
                                                params: URLParams,
                                            })}
                                            width="full"
                                            label="Book Online"
                                            labelAlign="center"
                                            variant="filled"
                                            color="secondary"
                                            size="small"
                                            shape="rounded"
                                            endIcon={<IconArrowRightCircled />}
                                            ripple={true}
                                        />
                                    )}
                                    {showCallNumber && (
                                        <a
                                            rel="nofollow"
                                            className={clsx(
                                                'block font-bold mt-2',
                                                styles['phone-link'],
                                            )}
                                            href={`tel:${FORMATTED_PHONE}`}
                                        >
                                            <IconPhone
                                                className="inline align-bottom lg:align-top mr-1"
                                                width="18"
                                            />
                                            {FORMATTED_PHONE}
                                        </a>
                                    )}
                                </div>
                            </GlobalHeaderCtaButtons>
                        </div>
                    </GlobalHeaderMainBar>
                </GlobalHeaderLite>
            </div>
        </>
    );
}
